import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff", // This makes the text color white
    },
    background: {
      default: "#F0D382", // Match the background color from the image (adjust hex as needed)
    },
    error: {
      main: "#D32F2F", // Color for the error state (red)
    },
  },
  typography: {
    allVariants: {
      color: "#ffffff", // Make all typography variants (h1, h2, body1, etc.) white
      fontFamily: `'Poppins', sans-serif`,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Set input text color to white
          borderColor: "#ffffff", // Set border color to white
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Input text color white
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffffff", // A slightly darker color for hover, change as necessary
            borderWidth: "2px", // A slightly darker color for hover, change as necessary
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D32F2F", // Red border when there's an error
          },
        },
        notchedOutline: {
          borderColor: "#ffffff", // Default border color white
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down("md")]: {
            "&.Mui-focused": {
              background: "#E8CF72",
              padding: "0 8px 0 4px",
              borderRadius: "12px",
            },
            "&.MuiFormLabel-filled": {
              background: "#E8CF72",
              padding: "0 8px 0 4px",
              borderRadius: "12px",
            },
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Make link text white
          whiteSpace: "nowrap",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#000000", // Button text color black
          backgroundColor: "#ffffff", // White background for the button
          textTransform: "capitalize",
        },
      },
    },
  },
});

export default theme;
