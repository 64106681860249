import { Box, Typography, Link } from "@mui/material";
import SocialIcons from "./SocialIcons";

const Footer = () => {
  return (
    <Box pt={3} pb={2}>
      <SocialIcons />
      <Typography variant="body2" align="right">
        Business &amp; Speaking Enquiries:{" "}
        <Link href="mailto:hello@fresh-partners.com">
          hello@fresh-partners.com
        </Link>
        <br />
        {/* Website:{" "}
          <Link href="https://www.michaelfasani.com/" target="_blank">
            Michael Fasani
          </Link>{" "}
          | Photography:{" "}
          <Link href="https://www.instagram.com/zakwalton/" target="_blank">
            Zak Walton
          </Link>
          <br /> */}
        &copy; 2024 Katie Piper. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
