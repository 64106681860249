import { Box } from "@mui/system";

const FullscreenWallpaper = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#E8CF72",
        zIndex: -1,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          maxHeight: { xs: "300px", sm: "300px", md: "500px", lg: "800px" },
          backgroundImage: "url(/img/katie-piper-background.jpg)",
          backgroundSize: "contain",
          backgroundPosition: "bottom left",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
    </Box>
  );
};

export default FullscreenWallpaper;
