import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IntroText from "./IntroText";

const HeaderPanel = () => {
  return (
    <Grid container spacing={0} mt={4}>
      <Grid
        size={{ xs: 4, sm: 3 }}
        sx={{
          backgroundImage: "url(/img/katie-piper-background-small.png)",
          backgroundSize: "contain",
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Grid size={{ xs: 8, sm: 9 }}>
        <Box
          sx={{
            height: { xs: "70px", sm: "100px" },
            width: "100%",
            backgroundImage: "url(/img/katie-piper-logo.png)",
            backgroundSize: "contain",
            backgroundPosition: "bottom center",
            backgroundRepeat: "no-repeat",
            marginBottom: "12px",
          }}
        />
        <Box mb={4}>
          <IntroText />
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderPanel;
