import React from "react";
import { Box } from "@mui/material";

const Logo = () => {
  return (
    <Box display="flex" justifyContent="center" my={4}>
      <Box
        component="img"
        src="/img/katie-piper-logo.png"
        alt="Katie Piper"
        width={{ xs: "200px", sm: "200px", md: "250px", lg: "300px" }}
        height="auto"
      />
    </Box>
  );
};

export default Logo;
