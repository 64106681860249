import { useRef } from "react";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { MyFormFields } from "../types";

const CustomMailChimpForm = ({
  status,
  message,
  onValidated,
}: {
  status: "sending" | "error" | "success" | null;
  message: string | Error | null;
  onValidated: (formData: MyFormFields) => void;
}) => {
  const formatMessage = (msg: string): string => {
    switch (true) {
      case msg.includes("0 - Please enter a value"):
        return "Email is required";
      case msg.includes("0 - An email address must contain a single @."):
        return "Email must contain a single @";
      case msg.includes(
        "0 - The domain portion of the email address is invalid",
      ):
        return "Email domain is invalid, the portion after the @ symbol";
      case msg.includes(
        "0 - The username portion of the email address is empty",
      ):
        return "Email username is invalid, the portion before the @ symbol";
      default:
        return msg; // Return the original message if no match is found
    }
  };

  const uiMessage = typeof message === "string" ? formatMessage(message) : "";

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const onSubmit = () => {
    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;
    const email = emailRef.current?.value;

    onValidated({
      EMAIL: String(email),
      FNAME: String(firstName),
      LNAME: String(lastName),
      tags: 2192, // Landing Page Tag
    });
  };

  return (
    <Box>
      <TextField
        inputRef={firstNameRef}
        label="First Name"
        variant="outlined"
        fullWidth
        margin="normal"
        sx={{
          marginTop: "1px",
        }}
      />
      <TextField
        inputRef={lastNameRef}
        label="Last Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        inputRef={emailRef}
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        required
        error={status === "error"}
      />
      {status && (
        <Box mt={2}>
          {status === "sending" && (
            <Alert severity="info">Signing you up...</Alert>
          )}
          {status === "error" && <Alert severity="error">{uiMessage}</Alert>}
          {status === "success" && (
            <Alert severity="success">{uiMessage}</Alert>
          )}
        </Box>
      )}
      <Box display="flex" alignItems="center" mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          size="large"
          focusRipple
        >
          Sign Up
        </Button>
        <Typography ml={2}>* required field</Typography>
      </Box>
    </Box>
  );
};

export default CustomMailChimpForm;
