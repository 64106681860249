import "./App.css";

import MailchimpSubscribe, { FormHooks } from "react-mailchimp-subscribe";
import { Box, Container } from "@mui/material";
import CustomMailChimpForm from "./components/CustomMailChimpForm";
import { MyFormFields } from "./types";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme";
import Footer from "./components/Footer";
import HeaderPanel from "./components/HeaderPanel";
import IntroText from "./components/IntroText";
import Logo from "./components/Logo";
import FullscreenWallpaper from "./components/FullscreenWallpaper";

function App() {
  const url =
    "//katiepiper.us17.list-manage.com/subscribe/post?u=5dc298b372e0f480d6b40ae79&amp;id=3e29f2fea1&amp;f_id=0067c2e1f0";

  return (
    <ThemeProvider theme={theme}>
      <FullscreenWallpaper />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column", // Stack children vertically
          minHeight: "100dvh", // Full height of the viewport
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box display={{ xs: "none", sm: "none", md: "block" }} pb={4}>
            <Logo />
            <IntroText />
          </Box>

          <Box display={{ xs: "block", sm: "block", md: "none" }}>
            <HeaderPanel />
          </Box>

          <MailchimpSubscribe
            url={url}
            render={({
              subscribe,
              status,
              message,
            }: FormHooks<MyFormFields>) => (
              <CustomMailChimpForm
                status={status}
                message={message}
                onValidated={(formData: MyFormFields) => subscribe(formData)}
              />
            )}
          />
        </Box>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default App;
