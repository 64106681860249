import { Typography } from "@mui/material";

const IntroText = () => {
  return (
    <Typography variant="body1" textAlign={"center"} fontWeight={"bold"} px={1}>
      Sign up for my weekly affirmations, plus exclusive updates on upcoming
      events and news, delivered straight to your inbox!
    </Typography>
  );
};

export default IntroText;
