import React from "react";
import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faFacebookF,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const SocialIcons: React.FC = () => {
  return (
    <Box display="flex" justifyContent="right">
      <IconButton
        href="https://www.instagram.com/katiepiper_"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagram}
          style={{ color: "white", marginLeft: 2, marginRight: 2 }}
        />
      </IconButton>
      <IconButton
        href="https://www.tiktok.com/@katiepiper_"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faTiktok}
          style={{ color: "white", marginLeft: 2, marginRight: 2 }}
        />
      </IconButton>
      <IconButton
        href="https://www.facebook.com/katiepiperofficial"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faFacebookF}
          style={{ color: "white", marginLeft: 2, marginRight: 2 }}
        />
      </IconButton>
      <IconButton
        href="https://x.com/KatiePiper_"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faXTwitter}
          style={{ color: "white", marginLeft: 2 }}
        />
      </IconButton>
    </Box>
  );
};

export default SocialIcons;
